<template>
  <div class="table-overflow">
    <table
      ref="resizeTable"
      border="1"
      class="table"
      @click="sortTable"
    >
      <thead>
        <slot name="header" />
      </thead>
      <tbody>
        <slot name="default" />
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { useSort } from '@/ManualApp/use/sort';

const props = defineProps({
  dataForSort: { type: Object, default: { header: {}, body: [] } },
});

const { sortTable } = useSort(props.dataForSort);
</script>

<style lang="scss">
@import '@/ManualApp/scss/vars.scss';
.vertical-line {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 1px;
  border-left: 2px dotted #ddd;
  height: 100vh;
}

.dotted-text-end {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.break-word {
  word-wrap: break-word;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.column-resize {
  max-width: 150px;
  &-body {
    max-width: 25px;
    width: 150px;
  }
}
.table-overflow {
  max-width: 100%;
  height: auto;
  white-space: nowrap;
  overflow: scroll;
}

.full-window-table {
  height: calc(100vh - 85px);
  margin-right: -15px;
  max-width: calc(100% + 15px);
  overflow: scroll;
}

.table {
  table-layout: fixed;
  border: 1px solid $border-color;
  background: $background-color;

  font-size: 12px;
  line-height: 100%;

  & td,
  & th {
    padding: 2px 4px;
  }
  & thead {
    font-weight: 700;
    position: sticky;
    top: 0;
    & th {
      background: $bg-secondary;
    }
  }

  & tbody {
    & tr {
      transition: background-color 0.3s ease;

      &:hover {
        background-color: rgba($link-color, 0.1);
      }

      &.disabled {
        background-color: rgba(#000, 0.1);
        &:hover {
          background-color: rgba(#000, 0.1);
        }
      }

      & td {
        & .scan-row {
          padding: 4px;
          transition: all ease 0.2s;
          & p {
            margin: 0;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          & img {
            margin-left: 5px;
            width: 30px;
            height: 30px;
            padding: 5px;
            border-radius: 15px;
            transition: all ease 0.2s;

            &:hover {
              background-color: rgba($link-color, 0.2);
            }
          }
          &:hover {
            border-radius: 4px;
            background-color: rgba($link-color, 0.1);
          }
        }
      }
    }
  }
}
</style>
